import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch
} from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { currentAuth } from '../recoils';
import { analytics } from '../utils/analytics';
import { ConfirmManager } from './ConfirmManager';
import { Dashboard } from './Dashboard';
import { Header } from './Header';
import { Landing } from './Landing';
import { LoadingPage } from './LoadingPage';
import { NoticeManager } from './NoticeManager';
import { Register } from './Register';
import { SessionManager } from './SessionManager';
import { TeamSelect } from './TeamSelect';

const getCn = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'stretch',
    fontFamily: theme.typography.fontFamily
  }
}));

export function Router({}) {
  const cn = getCn();
  // ログインしているかどうかで根っこのルーティングを切り替える
  const userInfoLoadable = useRecoilValueLoadable(currentAuth);

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <BrowserRouter>
        <ConfirmManager />
        <NoticeManager />
        <SessionManager />
        <Route component={TrackingManager} />
        <div className={cn.root}>
          {userInfoLoadable.state === 'hasValue' &&
          userInfoLoadable.contents ? (
            <SignedInRoot />
          ) : (
            <Landing />
          )}
        </div>
      </BrowserRouter>
    </React.Suspense>
  );
}

function SignedInRoot({}) {
  return (
    <>
      <Route path="/" component={Header} />
      <Route exact path="/" component={TeamSelect} />
      <Switch>
        <Route path="/register" component={Register} />
        <Route path="/:teamId/:tab?" component={Dashboard} />
      </Switch>
    </>
  );
}

function TrackingManager(props: RouteComponentProps) {
  React.useEffect(() => {
    analytics.pageView(props.location.pathname);
  }, [props.location.pathname]);
  return null;
}
