import firebase from 'firebase/app';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilStateLoadable } from 'recoil';
import { currentAuth } from '../recoils';

export function SessionManager({}) {
  const [currentAuthLoadable, setCurrentAuth] =
    useRecoilStateLoadable(currentAuth);

  // 認証状態を Subscribe する
  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      setCurrentAuth(user);
    });
  }, []);

  // 認証状態のセッションがないことが確認されたら、トップにリダイレクトする
  if (currentAuthLoadable.contents === null) {
    return <Redirect to="/" />;
  }

  return null;
}
