import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { completeTagsAtom } from '../recoils';

export interface AutocompleteTagsProps
  extends Partial<AutocompleteProps<string, true, true, true>> {
  teamId: string;
}

const useStyles = makeStyles(theme => ({
  autocomplete: {
    minWidth: 400,
    maxWidth: '100%'
  }
}));

export function AutocompleteTags({ teamId, ...props }: AutocompleteTagsProps) {
  const classes = useStyles();

  // タグの一覧
  const tags = useRecoilValue(completeTagsAtom(teamId));

  return (
    <Autocomplete
      multiple
      options={Array.from(tags)}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label="タグ"
          placeholder="タグを追加"
          helperText="エンターキーを押すと確定できます"
          fullWidth
        />
      )}
      disableClearable
      className={classes.autocomplete}
      {...props}
    />
  );
}
