import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { teamsAtom } from '../recoils';

export interface TeamIconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  id: string;
  button?: boolean;
}

const getCn = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: '#B4C1CC',
    borderRadius: 8,
    width: 160,
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#c8cacc'
    }
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: 0.5
  },
  img: {
    maxWidth: '90%'
  },
  chip: {
    position: 'absolute',
    top: 4,
    left: 4,
    borderRadius: 8
  },
  tooltip: {
    fontSize: '1rem',
    lineHeight: 1.1
  }
}));

export function TeamIcon({ id, button, ...props }: TeamIconProps) {
  const cn = getCn();

  const team = useRecoilValue(teamsAtom(id));

  return (
    <Tooltip
      open={Boolean(!team?.enabled && button)}
      arrow
      placement="top"
      title={
        <span className={cn.tooltip}>
          弊社から回答をお待ちいただく間、
          <br />
          こちらでデモをご覧いただけます
        </span>
      }
      disableHoverListener
    >
      <div className={classNames(cn.root, button && cn.button)} {...props}>
        {team?.enabled ? null : (
          <Chip
            label="お支払い情報 未設定"
            className={cn.chip}
            color="primary"
            size="small"
          />
        )}
        <img
          src={team?.iconUrl || require('../resources/default-icon.png')}
          alt=""
          className={classNames(cn.img, !team?.enabled && cn.disabled)}
        />
      </div>
    </Tooltip>
  );
}
