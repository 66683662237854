import { CircularProgress, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing() * 8
  }
}));

export function LoadingPage() {
  const cn = useStyles();

  return (
    <div className={cn.root}>
      <CircularProgress />
    </div>
  );
}
