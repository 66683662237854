import produce from 'immer';
import { useCallback } from 'react';
import { DefaultValue, RecoilState, useSetRecoilState } from 'recoil';

export function useImmerRecoilState<T, Default extends T | void>(
  recoilState: RecoilState<T | DefaultValue>,
  defaultValue: Default
) {
  const setter = useSetRecoilState(recoilState);

  return useCallback(
    (mutator: (value: Default extends T ? T : T | DefaultValue) => void) => {
      setter(current => {
        const value =
          defaultValue !== undefined && current instanceof DefaultValue
            ? defaultValue
            : current;
        return produce(value, mutator);
      });
    },
    []
  );
}
