import firebase from 'firebase/app';

export const dummyMemberJa = memo<IMember>(() => ({
  uid: '00000_dummy_user_ja',
  name: '氏名・学籍番号など',
  loginId: '',
  rights: true,
  tags: ['渋谷校'],
  currentPage: {
    uri: '',
    title: '閲覧中のページが表示されます',
    status: '学習の様子が確認できます',
    updatedAt: firebase.firestore.Timestamp.now()
  }
}));

/**
 * script のロード時に初期化できない変数をメモ化して使う
 * @param initializer 値を返す関数
 * @param cache ここには何も代入しない
 */
function memo<T>(initializer: () => T, cache?: T) {
  return () => {
    return cache || (cache = initializer());
  };
}
