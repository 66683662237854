import * as React from 'react';
import { requestWithAuth } from '../store/requestWithAuth';
import { LoadingButton } from './LoadingButton';

interface CustomerPortalLinkProps {
  teamId: string;
  children: React.ReactNode;
}

/** StripeのCustomer Portalを開くリンク。一時的なURLをfetchで取得し、そのURLに遷移する */
export function CustomerPortalLink(props: CustomerPortalLinkProps) {
  const [loading, setLoading] = React.useState(false);

  /**
   * StripeのCustomer Portalを開く。URLはAPIで毎回生成する
   */
  const openCustomerPortal = async () => {
    setLoading(true); // ページ遷移するので、ローディング状態はこのままにする
    try {
      const requestUrl = new URL(
        '/api/stripeCustomerPortalAdmin',
        location.href
      );
      requestUrl.searchParams.set('teamId', props.teamId);
      requestUrl.searchParams.set('enterprise', 'true');
      const { url } = await requestWithAuth(requestUrl.href, 'GET');
      location.href = url;
    } catch (error) {
      console.error(error);
      alert('エラーが発生しました。しばらくしてからもう一度お試しください。');
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      color="primary"
      onClick={openCustomerPortal}
    >
      {props.children}
    </LoadingButton>
  );
}
