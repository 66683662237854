import {
  Button,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, CreditCard, Face, Person } from '@material-ui/icons';
import * as React from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { atomFamily, useRecoilValue } from 'recoil';
import { useFirestore } from '../hooks/useFirebase';
import { useRecoilEffect } from '../hooks/useRecoilEffect';
import { useStats } from '../hooks/useStats';
import {
  completeTagsAtom,
  convertToMember,
  teamMemberIdsState,
  teamMembersAtom,
  teamsAtom
} from '../recoils';
import { analytics } from '../utils/analytics';
import { merge } from '../utils/merge';
import { DashboardAdmins } from './DashboardAdmins';
import { DashboardCreate } from './DashboardCreate';
import { DashboardMember } from './DashboardMember';
import { DashboardMembers } from './DashboardMembers';
import { DashboardPayments } from './DashboardPayments';
import { LoadingPage } from './LoadingPage';
import { TeamIcon } from './TeamIcon';

export type Params = {
  teamId: string;
  tab?: 'members' | 'admins' | 'payments' | 'create';
};

const getCn = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  right: {
    flex: 1,
    borderLeftColor: theme.palette.divider,
    borderLeftStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  scroll: {
    flex: 1,
    overflow: 'scroll',
    paddingBottom: 100
  },
  bar: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderWidth: 1
  },
  filterBar: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&>*': {
      marginRight: 16
    }
  },
  notFound: {
    padding: 24,
    '&>*': {
      marginBottom: 24
    }
  }
}));

const getTabCn = makeStyles(theme => ({
  wrapper: {
    flexDirection: 'row',
    '&>svg': {
      marginTop: 6
    }
  }
}));

export const loadingTeamMembers = atomFamily({
  key: 'loadingTeamMembers',
  default: (teamId: string) => true
});

export function Dashboard({}) {
  const cn = getCn();
  const tabCn = getTabCn();
  // 現在表示しようとしているチームを取得する
  const { teamId, tab } = useParams<Params>();
  const team = useRecoilValue(teamsAtom(teamId)); // 現在表示しようとしているチーム
  const isEmpty = useRecoilValue(teamMemberIdsState(teamId)).length === 0;

  // チームメンバーをクエリする
  const firestore = useFirestore();
  useRecoilEffect(
    ({ set }) => {
      set(loadingTeamMembers(teamId), true);
      // チームに所属しているメンバーの情報を全て取得する
      const unsubscribe = firestore
        .collection('teams')
        .doc(teamId)
        .collection('members')
        .withConverter(convertToMember)
        .onSnapshot(qs => {
          let uids: string[] = [];
          // データを格納する
          qs.forEach(ds => {
            const member = ds.data();
            set(teamMembersAtom({ teamId, uid: ds.id }), member);
            set(completeTagsAtom(teamId), curr => merge(curr, member.tags));
            uids.push(ds.id);
          });
          // uid の配列をマージする
          set(teamMemberIdsState(teamId), curr => merge(curr, uids));
          // ローディングインジケータを止める
          set(loadingTeamMembers(teamId), false);
        });
      return () => {
        unsubscribe();
      };
    },
    [teamId]
  );

  const history = useHistory();
  const changeTab = React.useCallback((e: any, value: string) => {
    analytics.changeTab(value);
    history.push(`/${teamId}/${value}`);
  }, []);

  // スクロールを戻す
  const location = useLocation();
  const scrollerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    scrollerRef.current?.scrollTo(0, 0);
  }, [location.pathname]);

  if (!team) {
    return (
      <div className={cn.notFound}>
        <Typography variant="h6">
          URL が間違っているか、存在しないチームです
        </Typography>
        <Button color="primary" onClick={() => history.push('/')}>
          トップに戻る
        </Button>
      </div>
    );
  }

  return (
    <div className={cn.root}>
      <TeamInfo />
      <div className={cn.right}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          className={cn.bar}
          onChange={changeTab}
        >
          <Tab
            value="members"
            icon={<Face />}
            classes={tabCn}
            label="メンバー"
          />
          <Tab
            value="admins"
            icon={<Person />}
            classes={tabCn}
            label="管理者"
          />
          <Tab
            value="payments"
            icon={<CreditCard />}
            classes={tabCn}
            label={
              <Tooltip
                open={team?.enabled === false && tab !== 'payments'}
                arrow
                disableHoverListener
                placement="top"
                title="お支払い情報を追加いただくと、今すぐ利用を開始できます"
              >
                <span>お支払い情報</span>
              </Tooltip>
            }
          />
          <Tab
            value="create"
            icon={<Add />}
            classes={tabCn}
            label={
              <Tooltip
                open={Boolean(team?.enabled && isEmpty && tab !== 'create')}
                arrow
                disableHoverListener
                placement="top"
                title="こちらで一人目のメンバー（生徒）を追加できます"
              >
                <span>メンバー追加</span>
              </Tooltip>
            }
          />
        </Tabs>
        <div className={cn.scroll} ref={scrollerRef}>
          <React.Suspense fallback={<LoadingPage />}>
            <Route
              exact
              path="/:teamId?/members"
              component={DashboardMembers}
            />
            <Route path="/:teamId?/members/:uid" component={DashboardMember} />
            <Route path="/:teamId?/admins" component={DashboardAdmins} />
            <Route path="/:teamId?/payments" component={DashboardPayments} />
            <Route path="/:teamId?/create" component={DashboardCreate} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

const infoStyles = makeStyles(theme => ({
  left: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column'
  },
  teamName: {
    marginTop: 4,
    marginBottom: 32
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 200,
    '&>*': {
      width: '50%',
      paddingBottom: 16
    }
  }
}));

function TeamInfo() {
  const classes = infoStyles();

  // 現在表示しようとしているチーム
  const { teamId } = useParams<Params>();
  const team = useRecoilValue(teamsAtom(teamId)); // 現在表示しようとしているチーム

  // 現在の席数など
  const { sum, has } = useStats(teamId);

  return (
    <div className={classes.left}>
      <TeamIcon id={teamId} />
      <Typography variant="h6" className={classes.teamName}>
        {team?.name}
      </Typography>
      <div>
        <div className={classes.column}>
          <Typography variant="body1" color="textSecondary">
            メンバー数
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {sum || '-'}
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography variant="body1" color="textSecondary">
            プラン
          </Typography>
          <Typography variant="body1" color="textSecondary">
            有料（法人）
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography variant="body1" color="textSecondary">
            有効人数
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {typeof has === 'number' ? has : ''}
          </Typography>
        </div>
      </div>
    </div>
  );
}
