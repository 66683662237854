import {
  atom,
  atomFamily,
  DefaultValue,
  GetRecoilValue,
  selector,
  selectorFamily,
  SerializableParam
} from 'recoil';

export interface AtomRewindOptions<T> {
  key: string;
  default: (opts: { get: GetRecoilValue }) => Promise<T>;
  dangerouslyAllowMutability?: boolean;
}

/**
 * reset() することで options.default を再実行する Atom
 */
export function atomRewind<T>(options: AtomRewindOptions<T>) {
  // reset または set された回数
  const setCount = atom({
    key: `${options.key}__setCount`,
    default: 0
  });

  // set された場合は値を保持
  // reset された場合は reset されたことを保持
  let overrideValue: T | DefaultValue = new DefaultValue();

  return selector<T>({
    key: options.key,
    dangerouslyAllowMutability: options.dangerouslyAllowMutability,
    get: ({ get }) => {
      get(setCount); // set と reset を購読
      return overrideValue instanceof DefaultValue
        ? options.default({ get }) // reset されたので関数を再実行
        : overrideValue; // set された値をそのまま返す
    },
    set: ({ set }, newValue) => {
      overrideValue = newValue;
      set(setCount, count => count + 1);
    }
  });
}

export interface AtomRewindFamilyOptions<T, P extends SerializableParam> {
  key: string;
  default: (params: P) => (opts: { get: GetRecoilValue }) => Promise<T>;
  dangerouslyAllowMutability?: boolean;
}

/**
 * reset() することで options.default を再実行する AtomFamily
 */
export function atomRewindFamily<T, P extends SerializableParam>(
  options: AtomRewindFamilyOptions<T, P>
) {
  // reset または set された回数
  const setCount = atomFamily({
    key: `${options.key}__setCount`,
    default: 0
  });

  // set された場合は値を保持
  // reset された場合はキーを削除
  const overrideValueMap = new Map<string, T>();

  return selectorFamily<T, P>({
    key: options.key,
    dangerouslyAllowMutability: options.dangerouslyAllowMutability,
    get:
      params =>
      ({ get }) => {
        get(setCount(params)); // set と reset を購読
        const key = JSON.stringify(params); // pseudo stringify
        return overrideValueMap.has(key)
          ? (overrideValueMap.get(key) as T) // set された値をそのまま返す
          : options.default(params)({ get }); // reset されたので関数を再実行
      },
    set:
      params =>
      ({ set }, newValue) => {
        const key = JSON.stringify(params); // pseudo stringify
        if (newValue instanceof DefaultValue) {
          overrideValueMap.delete(key);
        } else {
          overrideValueMap.set(key, newValue);
        }
        set(setCount(params), count => count + 1);
      }
  });
}
