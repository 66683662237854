import * as React from 'react';

export interface UnmountOnChangeProps<T> {
  value: T;
  children: JSX.Element;
}

export function UnmountOnChange<T>(props: UnmountOnChangeProps<T>) {
  const [_, forceUpdate] = React.useReducer(i => i + 1, 0);

  const previousValue = React.useRef(props.value);
  const isValueChanged = previousValue.current !== props.value;
  previousValue.current = props.value;

  React.useEffect(() => {
    if (isValueChanged) {
      forceUpdate();
    }
  });

  return isValueChanged ? null : props.children;
}
