import { StylesProvider } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/ja';
import 'normalize.css';
import * as React from 'react';
import { render } from 'react-dom';
import { RecoilRoot } from 'recoil';
import { Router } from './components/Router';
import './config/initializeApp';

function Main() {
  moment.locale('ja');

  return (
    <RecoilRoot>
      <StylesProvider injectFirst>
        <Router />
      </StylesProvider>
    </RecoilRoot>
  );
}

const root = document.getElementById('app');

if (!root) {
  throw new Error('HTML Error');
}
render(<Main />, root);
