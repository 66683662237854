import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { useFirestore } from '../hooks/useFirebase';
import {
  convertToTeam,
  currentAuth,
  popupAlert,
  teamIdsAtom,
  teamsAtom
} from '../recoils';
import flex from '../styles/flex.scss';
import { analytics } from '../utils/analytics';
import { LoadingPage } from './LoadingPage';
import { TeamIcon } from './TeamIcon';

const getCn = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: 32
  },
  header: {
    marginBottom: 24
  }
}));

export function TeamSelect() {
  const cn = getCn();
  const history = useHistory();
  const firestore = useFirestore();
  const uid = useRecoilValue(currentAuth)?.uid;
  const [initialized, setInitialized] = React.useState(false);
  const [teamIds, setTeamIds] = useRecoilState(teamIdsAtom);

  const setTeam = useRecoilCallback(({ set }, id: string, team?: ITeam) => {
    set(teamsAtom(id), team);
  }, []);
  const setAlert = useSetRecoilState(popupAlert);

  // 所属チームの情報を取得する
  React.useEffect(() => {
    if (!uid) return;
    setInitialized(false);
    return firestore
      .collection('teams')
      .where('admins', 'array-contains', uid)
      .withConverter(convertToTeam)
      .onSnapshot({
        next: snapshot => {
          snapshot.docChanges().forEach(({ type, doc }) => {
            if (type === 'added' || type === 'modified') {
              setTeam(doc.id, doc.data());
            } else {
              setTeam(doc.id);
            }
          });
          const ids = snapshot.docs.map(doc => doc.id);
          setTeamIds(ids);
          setInitialized(true);
        },
        error: e => {
          setInitialized(true);
          setAlert({
            severity: 'error',
            children: e.message
          });
        }
      });
  }, [uid]);

  React.useEffect(() => {
    if (initialized && teamIds.length === 0) {
      // １つもチームがない場合は /register に遷移する
      analytics.viewRegister();
      history.push('/register');
    }
  }, [teamIds, initialized]);

  return initialized ? (
    <div className={classNames(cn.root)}>
      <Typography variant="h6" className={cn.header}>
        所属チーム
      </Typography>
      <div className={flex.horizontal}>
        {teamIds.map(id => (
          <TeamSelectItem key={id} id={id} />
        ))}
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
}

interface TeamSelectItemProps {
  id: string;
}

function TeamSelectItem(props: TeamSelectItemProps) {
  const history = useHistory();
  const team = useRecoilValue(teamsAtom(props.id));

  return team ? (
    <div
      className={flex.vertical}
      style={{ marginRight: 32 }}
      onClick={() => history.push(`/${props.id}/members`)}
    >
      <TeamIcon id={props.id} button />
      <Typography variant="body1">{team.name}</Typography>
    </div>
  ) : null;
}
