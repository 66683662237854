import { Snackbar } from '@material-ui/core';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useRecoilErrorUpdate } from '../hooks/useRecoilErrorUpdate';
import { currentAuth, popupAlert } from '../recoils';

export function NoticeManager() {
  const [showing, setShowing] = React.useState<AlertProps>();

  // ステートが更新されたら Snackbar を表示する
  const alertFromRecoil = useRecoilValueLoadable(popupAlert);
  React.useEffect(() => {
    if (alertFromRecoil.state === 'hasValue') {
      setShowing(alertFromRecoil.contents);
    }
  }, [alertFromRecoil]);

  // それぞれのステートが更新されたら Snackbar を表示する
  useRecoilErrorUpdate(currentAuth, error => {
    setShowing({
      severity: 'error',
      children: 'ログインできませんでした。詳しくはコンソールを見てください'
    });
    console.error(error);
  });

  const handleClose = React.useCallback((event: any, reason?: string) => {
    if (reason === 'clickaway') return; // 画面クリックでは閉じない
    setShowing(undefined);
  }, []);

  return (
    <Snackbar
      open={Boolean(showing)}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} {...showing} />
    </Snackbar>
  );
}
