import {
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import 'react-credit-cards/es/styles.scss';
import { useParams } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil';
import Stripe from 'stripe';
import { endpoint } from '../env';
import { useStats } from '../hooks/useStats';
import { teamCustomers, teamsAtom } from '../recoils';
import { requestWithAuth } from '../store/requestWithAuth';
import { CustomerPortalLink } from './CustomerPortalLink';
import { Params } from './Dashboard';

export interface DashboardPaymentsProps {}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      marginBottom: 32
    }
  },
  divider: {
    marginLeft: -32,
    width: 'calc(100% + 64px)'
  }
}));

export function DashboardPayments(props: DashboardPaymentsProps) {
  const classes = useStyles();

  const { teamId } = useParams<Params>();
  const teamLoadable = useRecoilValueLoadable(teamsAtom(teamId));
  const isTeamDisabled =
    teamLoadable.state === 'hasValue' &&
    teamLoadable.contents?.enabled === false;

  // customerが削除されているのはおかしい
  const customerLoadable = useRecoilValueLoadable(teamCustomers(teamId));
  const isCustomerDeleted =
    customerLoadable.state === 'hasValue' && customerLoadable.contents?.deleted;

  if (isCustomerDeleted) {
    return (
      <div className={classes.root}>
        <Typography variant="h6">
          お客様のアカウントに問題が発生しています。お手数ですが、ハックフォープレイ社にお問い合わせ下さい
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Quantity />
      <Typography variant="h6">お支払い情報</Typography>
      {isTeamDisabled ? (
        <Alert severity="warning">
          この度はお申し込みありがとうございます。貴社は現在、仮登録いただいている状態です。
          <br />
          アカウントを有効化するため、下記リンクよりお支払い情報のご入力をお願いいたします。
        </Alert>
      ) : null}
      <CustomerPortalLink teamId={teamId}>
        {isTeamDisabled ? 'お支払い情報を入力する' : 'お支払い情報を変更する'}
      </CustomerPortalLink>
    </div>
  );
}

export function Quantity() {
  // 現在の席数などを確認する
  const { teamId } = useParams<Params>();
  const { max, has } = useStats(teamId);
  const [processing, setProcessing] = React.useState(false);

  // 利用人数を更新する
  // TODO: useRecoilCallback のパラメータは 0.0.9 で変更された
  const handleReload = useRecoilCallback(
    async ({ set }) => {
      setProcessing(true);
      try {
        const customer = await requestWithAuth<Stripe.Customer>(
          endpoint + `/teamCustomers?teamId=${teamId}`
        );
        set(teamCustomers(teamId), customer);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessing(false);
      }
    },
    [teamId]
  );

  // 有効化前は不要な情報なので出さない
  const team = useRecoilValue(teamsAtom(teamId));
  if (team?.enabled === false) {
    return null;
  }

  // リロード中
  if (processing) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Typography variant="body1">
        現在の有効利用人数{max === undefined ? 'を取得中' : `は ${max} 人`}です
      </Typography>
      {max !== has ? (
        <Alert severity="info">
          利用人数が変更されている可能性があります。
          <Button variant="contained" color="primary" onClick={handleReload}>
            再読み込み
          </Button>
        </Alert>
      ) : null}
    </div>
  );
}
