/**
 * アナリティクス関連の関数をまとめたヘルパー
 */
export const analytics = {
  /**
   * 新しいページを開いた時に page_view を更新する
   * @param path 開いたページの URI
   */
  pageView(path: string) {
    gtag('config', process.env.REACT_APP_GA_TRACKING_ID_ADMIN, {
      page_view: path
    });
  },
  /**
   * 新しい管理者を招待した時のイベント
   */
  inviteAdmin() {
    gtag('event', 'invite_admin');
  },
  /**
   * 管理者を除名しようとした時のイベント
   */
  deleteAdmin() {
    gtag('event', 'delete_admin');
  },
  /**
   * タブを切り替えた時のイベント
   * @tab タブの URI
   */
  changeTab(tab: string) {
    gtag('event', 'change_tab', {
      event_label: tab
    });
  },
  /**
   * 支払い情報を追加した時のイベント
   */
  addPaymentInfo() {
    gtag('event', 'add_payment_info');
  },
  /**
   * 支払い情報を削除した時のイベント
   */
  removePaymentInfo() {
    gtag('event', 'remove_payment_info');
  },
  /**
   * 新しいメンバーを作成した時のイベント
   */
  createMember() {
    gtag('event', 'create_member');
  },
  /**
   * メンバーの特典を有効化した時のイベント
   */
  enableMember() {
    gtag('event', 'enable_member');
  },
  /**
   * メンバーの特典を無効化した時のイベント
   */
  disableMember() {
    gtag('event', 'disable_member');
  },
  /**
   * チームが１つもない状態で /regiser に遷移した時のイベント
   */
  viewRegister() {
    gtag('event', 'view_register');
  }
};

function gtag(...args: any[]) {
  try {
    window.gtag && window.gtag(...args);
  } catch (error) {
    console.warn(error);
  }
}
