import * as React from 'react';

/**
 * 空の余白要素
 */
export function Blank(props: Parameters<typeof blankStyle>[0]) {
  const style = React.useMemo(() => {
    return blankStyle(props);
  }, Object.values(props));

  return <div style={style} />;
}

function blankStyle({ grow = 0, shrink = 0, basis = 0 }): React.CSSProperties {
  return {
    flexGrow: grow,
    flexShrink: shrink,
    flexBasis: basis
  };
}
