import firebase from 'firebase/app';

export type Body = Record<string, string | boolean | number> | Blob;

export class NotSignInError extends Error {
  constructor() {
    super('Please sign in');
    this.name = 'NotSignInError';
  }
}

export class HttpError extends Error {
  response?: Response;
  constructor(message: string, response?: Response) {
    super(message);
    this.response = response;
  }
}

export async function requestWithAuth<Result = any>(
  endpoint: string,
  method = 'GET',
  body?: Body
): Promise<Result> {
  const user = firebase.auth().currentUser;
  if (!user) throw new NotSignInError();
  const token = await user.getIdToken(true);
  const response = await fetch(endpoint, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': body instanceof Blob ? body.type : 'application/json'
    },
    body: body
      ? body instanceof Blob
        ? body
        : JSON.stringify(body)
      : undefined
  });
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new HttpError(errorMessage, response);
  }
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(text);
    }
    throw new Error('Parse Error');
  }
}
