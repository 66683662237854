import {
  Button,
  // IconButton,
  makeStyles,
  // Menu,
  // MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
// import { MoreVert } from '@material-ui/icons';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { adminDetailsAtom, teamsAtom } from '../recoils';
import { analytics } from '../utils/analytics';
import { Params } from './Dashboard';
import { InviteDialog } from './InviteDialog';
import { UserProfile } from './UserProfile';

const useStyles = makeStyles(theme => ({
  bar: {
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 32,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.palette.divider,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

/**
 * ダッシュボードの「管理者」タブ
 */
export function DashboardAdmins() {
  const classes = useStyles();

  // 現在の管理者の UID
  const { teamId } = useParams<Params>();
  const admins = useRecoilValue(teamsAtom(teamId))?.admins;

  // 管理者を招待する
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className={classes.bar}>
        <Typography variant="h6">管理者一覧</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          管理者を招待する
        </Button>
        <InviteDialog
          teamId={teamId}
          open={open}
          onClose={(e, reason) => reason !== 'backdropClick' && setOpen(false)}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell>ハックフォープレイでのユーザー名</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins
            ? admins.map(uid => (
                <AdminRow key={uid} teamId={teamId} uid={uid} />
              ))
            : null}
        </TableBody>
      </Table>
    </>
  );
}

interface AdminRowProps {
  teamId: string;
  uid: string;
}

function AdminRow(props: AdminRowProps) {
  // 管理者の詳細
  const adminDetail = useRecoilValue(adminDetailsAtom(props.teamId))[props.uid];

  // // メニュー
  // const anchorEl = React.useRef<HTMLButtonElement>(null);
  // const [open, setOpen] = React.useState(false);

  // const deleteAdmin = () => {
  //   // TODO: 実装する
  //   analytics.deleteAdmin();
  //   setOpen(false);
  // };

  if (!adminDetail) {
    return null;
  }

  return (
    <TableRow>
      <TableCell>{adminDetail.name}</TableCell>
      <TableCell>
        <UserProfile uid={props.uid} />
      </TableCell>
      <TableCell align="right">
        {/* <IconButton ref={anchorEl} onClick={() => setOpen(true)}>
          <MoreVert />
        </IconButton>
        <Menu
          open={open}
          anchorEl={anchorEl.current}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          getContentAnchorEl={null}
          onClose={() => setOpen(false)}
        >
          <MenuItem onClick={deleteAdmin}>管理者から除名する</MenuItem>
        </Menu> */}
      </TableCell>
    </TableRow>
  );
}
