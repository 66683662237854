import {
  Checkbox,
  Chip,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import firebase from 'firebase/app';
import moment from 'moment';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DefaultValue, useRecoilValue } from 'recoil';
import { useImmerRecoilState } from '../hooks/useImmerRecoilState';
import { useStats } from '../hooks/useStats';
import {
  completeTagsAtom,
  convertToPreference,
  currentAuth,
  defaultPreference,
  teamMemberIdsState,
  teamMembersAtom,
  teamPreference,
  teamPreferenceWrite,
  teamsAtom
} from '../recoils';
import { dummyMemberJa } from '../utils/dummy';
import { loadingTeamMembers, Params } from './Dashboard';
import { UserProfile } from './UserProfile';

const getCn = makeStyles(theme => ({
  bar: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderWidth: 1
  },
  filterBar: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&>*': {
      marginRight: 16
    }
  }
}));

/**
 * ダッシュボードの「メンバー」タブ
 */
export function DashboardMembers() {
  const cn = getCn();

  // これ以上特典を利用できるか確認する
  const { teamId } = useParams<Params>();
  const { canIncrease, over } = useStats(teamId);

  // 現在のチームメンバーの ID
  const memberIds = useRecoilValue(teamMemberIdsState(teamId));

  // 絞り込みフィルタ
  const preference = useRecoilValue(teamPreference(teamId));
  const filters = preference?.filters;

  const enabled = useRecoilValue(teamsAtom(teamId))?.enabled;

  const loading = useRecoilValue(loadingTeamMembers(teamId));

  return (
    <>
      <div className={classNames(cn.bar, cn.filterBar)}>
        <Typography variant="body1">フィルタ</Typography>
        {filters?.map((filter, index) =>
          filter.type === 'rights' ? (
            <RightsFilter key={index} index={index} filter={filter} />
          ) : filter.type === 'tags' ? (
            <TagsFilter key={index} index={index} filter={filter} />
          ) : (
            <div>Invalid Filter</div>
          )
        ) || null}
      </div>
      {over ? (
        <Alert severity="warning">
          有効人数の上限を超えています。課金が発生する前に、人数を調整してください
        </Alert>
      ) : null}
      {enabled === false ? (
        <Alert severity="info">
          こちらではメンバーを管理できます。アカウントの有効化後にご利用いただけます。
        </Alert>
      ) : null}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>メンバー</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>今見ているページ</TableCell>
            <TableCell>最終更新</TableCell>
            <TableCell>タグ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enabled === false ? (
            <MemberRow
              teamId={teamId}
              uid={dummyMemberJa().uid}
              canIncrease={false}
            />
          ) : null}
          {memberIds.map(id => (
            <MemberRow
              key={id}
              teamId={teamId}
              uid={id}
              canIncrease={canIncrease}
            />
          ))}
        </TableBody>
      </Table>
      {loading ? (
        <Typography variant="h6">メンバーを取得しています</Typography>
      ) : null}
      <PreferenceUpdateProvider />
    </>
  );
}

interface RightsFilterProps {
  filter: RightsFilterType;
  index: number;
}

function RightsFilter({ filter, index }: RightsFilterProps) {
  const { teamId } = useParams<Params>();

  // フラグを切り替える
  const setPreference = useImmerRecoilState(
    teamPreferenceWrite(teamId),
    defaultPreference
  );
  const handleSwitch = (key: Exclude<keyof RightsFilterType, 'type'>) => () => {
    setPreference(draft => {
      const target = draft.filters?.[index];
      if (!target) return; // type hint
      Object.assign(target, { [key]: !filter[key] });
    });
  };

  // メニュー
  const anchorEl = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Chip
        clickable
        size="small"
        label={`特典：${
          filter.showEnabled ? (filter.showDisabled ? '全て' : 'あり') : 'なし'
        }`}
        ref={anchorEl}
        onClick={() => setOpen(true)}
      />
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={handleSwitch('showEnabled')}>
          <Checkbox checked={filter.showEnabled} />
          特典あり
        </MenuItem>
        <MenuItem onClick={handleSwitch('showDisabled')}>
          <Checkbox checked={filter.showDisabled} />
          特典なし（休止中）
        </MenuItem>
        {filter.showEnabled || filter.showDisabled ? null : (
          <Alert severity="warning">どちらかをオンにして下さい</Alert>
        )}
      </Menu>
    </>
  );
}

interface TagsFilterProps {
  filter: TagsFilterType;
  index: number;
}

function TagsFilter({ filter, index }: TagsFilterProps) {
  const { teamId } = useParams<Params>();

  // タグ一覧を取得する
  const tags = useRecoilValue(completeTagsAtom(teamId));

  // タグの表示/非表示を切り替える
  const setPreference = useImmerRecoilState(
    teamPreferenceWrite(teamId),
    defaultPreference
  );
  const handleSwitch = (tag: string) => () => {
    const showTags = filter.showTags.includes(tag)
      ? filter.showTags.filter(t => t !== tag)
      : filter.showTags.concat(tag);
    setPreference(draft => {
      const target = draft.filters?.[index];
      if (target) {
        Object.assign(target, {
          showEmpty: false,
          showTags
        });
      }
    });
  };
  const handleAll = React.useCallback(() => {
    setPreference(draft => {
      const target = draft.filters?.[index];
      if (target) {
        Object.assign(target, {
          showEmpty: false,
          showTags: []
        });
      }
    });
  }, []);
  const handleEmpty = React.useCallback(() => {
    setPreference(draft => {
      const target = draft.filters?.[index];
      if (target) {
        Object.assign(target, {
          showEmpty: true,
          showTags: []
        });
      }
    });
  }, []);

  // メニュー
  const anchorEl = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Chip
        clickable
        size="small"
        label={
          filter.showEmpty
            ? 'タグが空である'
            : `タグ：${
                filter.showTags.length ? filter.showTags.join() : '全て'
              }`
        }
        ref={anchorEl}
        onClick={() => setOpen(true)}
      />
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={handleAll}>
          <Checkbox checked={!filter.showEmpty && !filter.showTags.length} />
          全てを表示する
        </MenuItem>
        <Divider />
        {tags.map(tag => (
          <MenuItem key={tag} onClick={handleSwitch(tag)}>
            <Checkbox checked={filter.showTags.includes(tag)} />
            {tag}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleEmpty}>
          <Checkbox checked={filter.showEmpty} />
          タグが空
        </MenuItem>
        <Alert severity="info">
          タグを追加するにはメンバーをクリックして下さい
        </Alert>
      </Menu>
    </>
  );
}

const useStylesMemberRow = makeStyles(theme => ({
  root: {
    cursor: 'pointer'
  },
  currentPage: {
    '& > *': {
      maxWidth: '16em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    transition: theme.transitions.create('opacity')
  },
  old: {
    opacity: 0.9
  },
  older: {
    opacity: 0.1
  },
  noRights: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  }
}));

interface MemberRowProps {
  teamId: string;
  uid: string;
  canIncrease: boolean;
}

function MemberRow({ teamId, uid }: MemberRowProps) {
  const cn = useStylesMemberRow();

  // メンバー詳細を開く
  const history = useHistory();
  const handleOpen = React.useCallback(() => {
    if (uid === dummyMemberJa().uid) return; // ダミーデータの詳細はない
    history.push(`/${teamId}/members/${uid}`);
  }, [teamId, uid]);

  const member = useRecoilValue(teamMembersAtom({ teamId, uid }));
  // console.warn(uid, member);
  const millis = member?.currentPage?.updatedAt?.toMillis();
  const isOld = millis && Date.now() - millis > 60 * 1000; // １分経ったら少し薄くする
  const isOlder = millis && Date.now() - millis > 600 * 1000; // １０分経ったら極限まで薄くする
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    // ３０秒おきに更新する
    const id = window.setTimeout(() => setValue(value + 1), 30 * 1000);
    return () => window.clearTimeout(id);
  }, [value]);
  const fromNow = (millis && moment(millis).fromNow()) || '';

  return member ? (
    <TableRow
      hover
      className={classNames(cn.root, member.rights || cn.noRights)}
      onClick={handleOpen}
    >
      <TableCell>
        <UserProfile uid={member.uid} />
      </TableCell>
      <TableCell>{member.name}</TableCell>
      <TableCell
        className={classNames(
          cn.currentPage,
          isOld && cn.old,
          isOlder && cn.older
        )}
      >
        {member.disableCurrentPage ? (
          <Typography variant="body2" color="textSecondary">
            表示できない設定になっています
          </Typography>
        ) : member.currentPage ? (
          <>
            <a
              href={`https://www.hackforplay.xyz/${member.currentPage.uri}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
              title={member.currentPage.title}
            >
              {member.currentPage.title}
            </a>
            <Typography variant="body2" color="textSecondary">
              {member.currentPage.status}
            </Typography>
          </>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell>{member.disableCurrentPage ? '' : fromNow}</TableCell>
      <TableCell>
        {[...(member.tags || [])].map(tag => (
          <Chip key={tag} size="small" label={tag} />
        ))}
      </TableCell>
    </TableRow>
  ) : null;
}

/**
 * TODO: コンポーネントから分離する
 */
function PreferenceUpdateProvider() {
  const uid = useRecoilValue(currentAuth)?.uid;
  const { teamId } = useParams<Params>();
  const local = useRecoilValue(teamPreferenceWrite(teamId));
  const previousLocal = React.useRef(local);

  const changedRef = React.useRef(0); // ローカルで変更された回数
  const [updated, setUpdated] = React.useState(0); // アップデートが完了した回数
  const updatingRef = React.useRef(new Set<string>()); // 更新中の teamId を保持する

  React.useEffect(() => {
    if (local instanceof DefaultValue) {
      return; // 一度も変更されていないか、リセットされた
    }
    const { filters } = local;
    if (!uid || !filters) return; // type hint
    if (updatingRef.current.has(teamId)) {
      return; // 他のものが更新中
    }
    if (local !== previousLocal.current) {
      // local が変更された
      changedRef.current++;
      previousLocal.current = local;
    }
    const changed = changedRef.current;
    if (changed === updated) return; // ローカルの変更はない

    // 更新開始
    updatingRef.current.add(teamId);
    firebase
      .firestore()
      .collection('teams')
      .doc(teamId)
      .collection('preferences')
      .doc(uid)
      .withConverter(convertToPreference)
      .set({ filters }, { merge: true })
      .then(() => {
        // 更新完了. もしローカルの変更があれば続けて更新させる
        updatingRef.current.delete(teamId);
        setUpdated(changed);
      });
  }, [local, updated]);

  return null;
}
