import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import * as React from 'react';
import { useRecoilCallback } from 'recoil';
import { endpoint } from '../env';
import { adminDetailsAtom, popupAlert } from '../recoils';
import { requestWithAuth } from '../store/requestWithAuth';
import { analytics } from '../utils/analytics';

export interface InviteDialogProps extends DialogProps {
  teamId: string;
}

type InviteAdmin = { teamId: string; email: string; name: string };

const useStyles = makeStyles(theme => ({
  content: {
    minWidth: 400,
    '&>*': {
      marginBottom: 32
    }
  }
}));

export function InviteDialog({ teamId, ...props }: InviteDialogProps) {
  const classes = useStyles();

  // 追加したいユーザーのメールアドレスと表示名
  const [email, setEmail] = React.useState('');
  const invalid = !/.+@.+\..+/.test(email);
  const [name, setName] = React.useState('');

  // 送信
  const [sending, setSending] = React.useState(false);
  const [successed, setSuccessed] = React.useState(false); // 招待に成功した
  const send = useRecoilCallback(
    async ({ set }, params: InviteAdmin) => {
      analytics.inviteAdmin();
      setSending(true);
      try {
        const result = await requestWithAuth<IAdminDetail>(
          endpoint + '/admins',
          'POST',
          params
        );
        setSuccessed(true);
        set(popupAlert, {
          severity: 'success',
          children: `${params.name} を管理者に招待しました`
        });
        set(adminDetailsAtom(teamId), value => ({
          ...value,
          [result.uid]: result
        }));
      } catch (error) {
        set(popupAlert, {
          severity: 'error',
          children: `エラーが発生し、${params.name} を招待できませんでした。(${error.message})`
        });
      }
      setSending(false);
    },
    [teamId]
  );

  // ダイアログを閉じる
  const close = React.useCallback(() => {
    props.onClose?.({}, 'escapeKeyDown');
  }, [props.onClose]);

  // 次の人を招待する
  const reset = React.useCallback(() => {
    setSending(false);
    setSuccessed(false);
    setEmail('');
    setName('');
  }, []);

  // URL をコピーする
  const urlRef = React.useRef<HTMLInputElement>(null);
  const [copied, setCopied] = React.useState(false);
  const copy = React.useCallback(() => {
    urlRef.current?.select();
    const copied = document.execCommand('copy');
    setCopied(copied);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, []);

  return (
    <Dialog {...props}>
      {!successed ? (
        <>
          <DialogTitle>管理者を招待する</DialogTitle>
          <DialogContent className={classes.content}>
            <TextField
              label="メールアドレス"
              onChange={event => setEmail(event.target.value)}
              error={Boolean(email) && invalid}
              helperText="招待するメールアドレス。Google アカウントである必要があります"
              required
              disabled={sending}
            />
            <TextField
              label="表示名"
              onChange={event => setName(event.target.value)}
              helperText="ダッシュボード上で表示される名前です。いつでも変更できます"
              required
              disabled={sending}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={invalid || !name || sending}
              onClick={() => send({ email, name, teamId })}
            >
              招待する
            </Button>
            <Button onClick={close}>とじる</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>管理者を追加しました</DialogTitle>
          <DialogContent className={classes.content}>
            <Typography variant="body1">
              {name} をこのチームの管理者に追加しました
            </Typography>
            <Typography variant="body1">
              下のリンクを {name} に送って、Google
              でログインするよう伝えて下さい
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              label="招待用 URL"
              value={`${window.location.origin}?login_hint=${encodeURIComponent(
                email
              )}`}
              inputRef={urlRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="コピーしました" open={copied}>
                      <IconButton onClick={copy}>
                        <FileCopy />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              helperText="右のボタンを押すとコピーできます"
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={reset}>
              別の人を招待する
            </Button>
            <Button onClick={close}>とじる</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
