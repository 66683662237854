import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import firebase from 'firebase/app';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useFirestore } from '../hooks/useFirebase';
import {
  memberWorksState,
  passwordHistoriesState,
  popupAlert,
  teamMembersAtom,
  teamPreference
} from '../recoils';
import { analytics } from '../utils/analytics';
import { AutocompleteTags } from './AutocompleteTags';
import { LoadingPage } from './LoadingPage';
import { UnmountOnChange } from './UnmountOnChange';
import { UpdatePasswordDialog } from './UpdatePasswordDialog';
import { UserProfile } from './UserProfile';

type Params = {
  teamId: string;
  uid: string;
};

const useStyles = makeStyles(theme => ({
  bar: {
    paddingLeft: 32,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderWidth: 1,
    color: theme.palette.text.secondary
  },
  content: {
    padding: 32,
    '&>*': {
      marginBottom: 32
    }
  },
  autocomplete: {
    maxWidth: 500
  },
  block: {
    display: 'block'
  }
}));

const img404 = 'https://cdn.hackforplay.xyz/ogp-image/not-found-bomy.png';

/**
 * ダッシュボードの「メンバー」からメンバー詳細を開いたページ
 */
export function DashboardMember() {
  const classes = useStyles();

  // メンバーを取得
  const { teamId, uid } = useParams<Params>();
  const member = useRecoilValue(teamMembersAtom({ teamId, uid }));
  const filters = useRecoilValue(teamPreference(teamId))?.filters;

  const showing = React.useMemo(() => {
    if (!member) return false;
    if (!filters) return true;
    return filters.some(
      filter =>
        filter.type === 'rights'
          ? (filter.showEnabled && member.rights) ||
            (filter.showDisabled && !member.rights)
          : filter.type === 'tags'
          ? (filter.showEmpty && !member.tags?.length) ||
            (!filter.showEmpty && !filter.showTags.length) ||
            filter.showTags.some(tag => member.tags?.includes(tag))
          : true // バグ
    );
  }, [member, filters]);

  const firestore = useFirestore();
  const [updated, setUpdated] = React.useState<Partial<IMember>>({});
  const updateMember = useRecoilCallback(
    async ({ set }, payload: Partial<IMember>) => {
      setEditing(false);
      // チームメンバーのドキュメントを変更する
      // disableCurrentPage=true にした時だけ、現在の currentPage を削除する
      const updated = payload.disableCurrentPage
        ? {
            ...payload,
            currentPage: firebase.firestore.FieldValue.delete()
          }
        : payload;
      try {
        await firestore
          .collection('teams')
          .doc(teamId)
          .collection('members')
          .doc(uid)
          .update(updated);
        // ストアの情報を上書きする
        set(teamMembersAtom({ teamId, uid }), curr =>
          curr ? { ...curr, ...payload } : undefined
        );
      } catch (error) {
        set(popupAlert, {
          severity: 'error',
          children: '更新に失敗しました。再読み込みしてください'
        });
        setEditing(true);
      }
    },
    [teamId, uid, firestore]
  );

  // 名前を変更する
  const [editing, setEditing] = React.useState(false);

  return (
    <>
      <div className={classes.bar}>
        <Button color="inherit" component={Link} to={`/${teamId}/members`}>
          <ArrowBack />
          メンバー一覧に戻る
        </Button>
      </div>
      {showing ? (
        <div className={classes.content}>
          <UserProfile uid={uid} />
          <UnmountOnChange value={member?.name}>
            <TextField
              label="管理用の名前"
              disabled={!editing}
              defaultValue={updated.name || member?.name || ''}
              onChange={({ target }) =>
                setUpdated(p => ({ ...p, name: target.value }))
              }
            />
          </UnmountOnChange>
          <UnmountOnChange value={member?.tags}>
            <AutocompleteTags
              disabled={!editing}
              defaultValue={member?.tags || []}
              teamId={teamId}
              onChange={(e, tags) => setUpdated(p => ({ ...p, tags }))}
            />
          </UnmountOnChange>
          <Button
            variant="contained"
            color="primary"
            onClick={() => (editing ? updateMember(updated) : setEditing(true))}
          >
            {editing ? '完了' : '編集する'}
          </Button>
          <Typography variant="h5">最近のステージ</Typography>
          <Typography variant="body1" color="textSecondary">
            管理者はメンバーの未公開作品を閲覧できます。直近５個まで表示しています。
          </Typography>
          <React.Suspense fallback={<LoadingPage />}>
            <MemberWorks />
          </React.Suspense>
          <Typography variant="h5">パスワード</Typography>
          <Typography variant="body1" color="textSecondary">
            管理者はメンバーのパスワードを変更できます。変更した場合はここにその履歴が表示されます。
          </Typography>
          <React.Suspense fallback={<LoadingPage />}>
            <PasswordHistories />
          </React.Suspense>
          <UpdatePasswordDialog teamId={teamId} uid={uid} />
          <TextField
            disabled
            label="ログイン ID"
            value={member?.loginId || ''}
            helperText={
              member?.loginId
                ? 'ログイン ID は変更できません'
                : '表示できません'
            }
            className={classes.block}
          />
          <Typography variant="h5">高度な設定</Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(member?.disableCurrentPage)}
                  onChange={(e, checked) =>
                    updateMember({
                      disableCurrentPage: checked // disableCurrentPage=true にすると currentPage を更新しない
                    })
                  }
                />
              }
              label="このメンバーが見ているページを秘密にする"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!member?.rights}
                  onChange={(e, checked) => {
                    checked
                      ? analytics.enableMember()
                      : analytics.disableMember();
                    updateMember({
                      rights: !checked
                    });
                  }}
                />
              }
              label="このメンバーを一時的に休止状態にする"
            />
            <Typography variant="body2" color="textSecondary">
              休止状態のメンバーには、法人プランの特典をご利用いただけません。特典に関する詳細は
              <a
                href="https://www.hackforplay.xyz/pages/post/enterprise"
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              をご覧ください
            </Typography>
          </div>
        </div>
      ) : (
        <Typography variant="h6">存在しないユーザーです</Typography>
      )}
    </>
  );
}

const useStylesMemberWorks = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    width: 240,
    overflow: 'hidden',
    marginRight: 16,
    '&>img': {
      width: '100%'
    }
  },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    textDecoration: 'none'
  },
  detail: {
    display: 'flex',
    alignItems: 'center'
  }
}));

function MemberWorks() {
  const cn = useStylesMemberWorks();
  const { teamId, uid } = useParams<Params>();
  const works = useRecoilValue(memberWorksState({ teamId, uid }));

  return (
    <>
      <div className={cn.root}>
        {works && works.length > 0 ? (
          <>
            {works.map((work, i) => (
              <div key={i} className={cn.container}>
                <img src={work.thumbnailUrl || img404} alt="" />
                <Button
                  color="primary"
                  href={work.path && `https://www.hackforplay.xyz/${work.path}`}
                  target="_blank"
                  className={cn.button}
                >
                  {work.title || <i>タイトルがついていません</i>}
                </Button>
                <Chip
                  size="small"
                  label={work.visibility === 'public' ? '公開' : '限定公開'}
                />
                <Typography variant="body2" color="textSecondary">
                  {toString(work.createdAt, 'に作成')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {toString(work.updatedAt, 'に更新')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {toString(work.publishedAt, 'に公開')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {toString(work.deletedAt, 'に削除')}
                </Typography>
              </div>
            ))}
          </>
        ) : works?.length === 0 ? (
          <Typography variant="body1">まだステージがありません</Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            読み込み中です
          </Typography>
        )}
      </div>
    </>
  );
}

const useStylesPasswordHistories = makeStyles(theme => ({
  root: {
    '&>div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  timestamp: {
    marginRight: 16
  }
}));

function PasswordHistories() {
  const cn = useStylesPasswordHistories();

  const { teamId, uid } = useParams<Params>();
  const passwordHistories = useRecoilValue(
    passwordHistoriesState({ teamId, uid })
  );

  return passwordHistories ? (
    passwordHistories.length > 0 ? (
      <div className={cn.root}>
        {passwordHistories.map((item, i) => (
          <div key={i}>
            <Typography variant="body1" className={cn.timestamp}>
              {item.updatedAt.toDate().toLocaleString()}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              変更者：
            </Typography>
            <UserProfile uid={item.updatedBy} />
          </div>
        ))}
      </div>
    ) : (
      <Typography variant="body1">
        パスワードが変更された履歴はありません。
      </Typography>
    )
  ) : (
    <Typography variant="body1" color="textSecondary">
      読み込み中です
    </Typography>
  );
}

function toString(date: any, suffix: string) {
  const ms = (date?._seconds || 0) * 1000 + (date?._nanoseconds || 0) / 1000000;
  return ms ? new Date(ms).toLocaleString() + suffix : '';
}
