import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { endpoint } from '../env';
import {
  passwordHistoriesState,
  popupAlert,
  teamMembersAtom
} from '../recoils';
import { requestWithAuth } from '../store/requestWithAuth';
import { useConfirm } from './ConfirmManager';
import { PasswordTextField } from './PasswordTextField';
import { UserProfile } from './UserProfile';

const useStyles = makeStyles(theme => ({
  content: {
    '&>*': {
      display: 'block',
      marginBottom: 24
    }
  }
}));

export interface UpdatePasswordDialogProps {
  teamId: string;
  uid: string;
}

export function UpdatePasswordDialog({
  teamId,
  uid
}: UpdatePasswordDialogProps) {
  const cn = useStyles();

  // メンバーを取得
  const member = useRecoilValue(teamMembersAtom({ teamId, uid }));

  const [showing, setShowing] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [pConfirm, setConfirm] = React.useState('');
  const passwordIsTooShort = password.length < 8; // パスワードが８文字未満である
  const confirmIsNotSame = password !== pConfirm; // パスワードが一致しない

  const confirm = useConfirm();
  const [loading, setLoading] = React.useState(false);
  const change = useRecoilCallback(
    async ({ set, reset }) => {
      if (!teamId || !uid || !password) return;
      if (
        !(await confirm(
          '本当にパスワードを変更してもよろしいですか？',
          'はい、変更します',
          'やめておきます'
        ))
      ) {
        return;
      }
      setLoading(true);
      try {
        // チームメンバーのパスワードを変更する
        await requestWithAuth(endpoint + '/memberPasswords', 'PUT', {
          teamId,
          memberId: uid,
          password
        });
        set(popupAlert, {
          severity: 'success',
          children: `パスワードを変更出来ました`
        });
        // 再取得させる
        reset(passwordHistoriesState({ teamId, uid }));

        // 初期化してダイアログを閉じる
        setPassword('');
        setConfirm('');
        setShowing(false);
      } catch (error) {
        console.error(error);
        set(popupAlert, {
          severity: 'error',
          children: `パスワードを変更出来ませんでした。お問い合わせください`
        });
      }
      setLoading(true);
    },
    [teamId, uid, password]
  );

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={!member}
        onClick={() => setShowing(true)}
      >
        パスワード変更
      </Button>
      <Dialog open={showing} onClose={() => setShowing(false)}>
        <DialogTitle>パスワードを変更する</DialogTitle>
        {member ? (
          <DialogContent className={cn.content}>
            <Typography variant="body1">
              このユーザーのパスワードを変更しようとしています
            </Typography>
            <div>
              <UserProfile uid={member.uid} />
            </div>
            <Typography variant="body1" color="textSecondary">
              管理者ページでメンバーを作成した時に設定したパスワードを変更できます。
              <br />
              Googleアカウントのパスワードはこちらでは変更できません。
              <br />
              パスワードの機密上、一度変更したパスワードを元に戻すことはできません（再度設定し直す必要があります）。
              <br />
              あなたがパスワードを変更したことと、その時刻はシステムに記録され、他の管理者が見られるようになります。
            </Typography>
            <PasswordTextField
              variant="outlined"
              required
              label="パスワード"
              value={password}
              error={Boolean(password && passwordIsTooShort)}
              onChange={e => setPassword(e.target.value)}
              helperText="パスワードは８文字以上である必要があります"
            />
            <PasswordTextField
              variant="outlined"
              required
              label="パスワードの確認"
              value={pConfirm}
              error={confirmIsNotSame}
              onChange={e => setConfirm(e.target.value)}
              helperText="パスワードと一致している必要があります"
            />
          </DialogContent>
        ) : (
          <Alert severity="error">エラーが発生しました</Alert>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !password || passwordIsTooShort || confirmIsNotSame || loading
            }
            onClick={change}
          >
            変更する
          </Button>
          <Button onClick={() => setShowing(false)}>とじる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
