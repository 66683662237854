import { useEffect } from 'react';
import { RecoilState, useRecoilValueLoadable } from 'recoil';

interface Callback {
  (error: Error): void | (() => void);
}

/**
 * Recoil State にエラーがあるとき、コールバックを実行する
 * @param state Atom or Selector
 */
export function useRecoilErrorUpdate<T = unknown>(
  state: RecoilState<T>,
  callback: Callback
) {
  const loadable = useRecoilValueLoadable(state);
  useEffect(() => {
    if (loadable.state !== 'hasError') {
      return; // エラーではないのでスキップ
    }
    return callback(loadable.contents);
  }, [loadable]);
}
