import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { getUserById } from '../recoils';

export interface UserProfileProps {
  uid: string;
  component?: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden'
  },
  icon: {
    width: 48
  },
  text: {
    maxWidth: '15vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noName: {
    fontStyle: 'italic'
  }
}));

export function UserProfile(props: UserProfileProps) {
  const classes = useStyles();

  // 必要に応じてユーザーを取得する
  const user = useRecoilValueLoadable(getUserById(props.uid));

  return (
    <div className={classes.root}>
      {user.state === 'hasValue' ? (
        <>
          <img
            src={user.contents?.iconUrl || ''}
            className={classes.icon}
            alt=""
          />
          <Typography
            variant="body1"
            className={classNames(
              classes.text,
              !user.contents?.displayName && classes.noName
            )}
          >
            {user.contents?.displayName || 'ニックネームが設定されていません'}
          </Typography>
        </>
      ) : user.state === 'hasError' ? (
        <Typography variant="body1" className={classes.text}>
          エラーが発生しました
        </Typography>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
