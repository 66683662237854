/**
 * 配列をコピーして新しい参照を作り出す
 * 新しい要素がひとつも追加されない場合は
 * source と同じ参照を返す
 */
export function merge<T>(source: T[], added?: T[]) {
  if (!added?.length) return source;
  const copy = Array.from(source); // deep copy
  for (const item of added) {
    if (!copy.includes(item)) {
      copy.push(item);
    }
  }
  return copy.length === source.length ? source : copy;
}
