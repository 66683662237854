import {
  AppBar,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar
} from '@material-ui/core';
import firebase from 'firebase/app';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentAuth } from '../recoils';
import { Blank } from './Blank';
import { LoadingPage } from './LoadingPage';

const getCn = makeStyles(theme => ({
  header: {
    backgroundColor: '#edf0f3'
  },
  logo: {
    height: 32
  }
}));

export function Header({}) {
  const cn = getCn();

  return (
    <AppBar position="relative" className={cn.header}>
      <Toolbar variant="dense">
        <Link to="/">
          <img
            src={require('../resources/hackforplay-admin.png')}
            className={cn.logo}
            alt=""
          />
        </Link>
        <Blank grow={1} />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

const useStylesUserMenu = makeStyles(theme => ({
  displayName: {
    textTransform: 'none'
  }
}));

function UserMenu() {
  const cn = useStylesUserMenu();

  const userInfo = useRecoilValue(currentAuth);

  const signOut = React.useCallback(() => {
    firebase.auth().signOut();
  }, []);

  // メニュー
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Button
        size="small"
        onClick={() => setMenuOpen(true)}
        ref={anchorEl}
        className={cn.displayName}
      >
        {userInfo?.displayName || ''}
        {` (Google アカウント)`}
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem disabled={!userInfo} onClick={signOut}>
          ログアウト
        </MenuItem>
      </Menu>
    </React.Suspense>
  );
}
